import { Chip } from '@mui/material';
import { RAG } from '../../constants/RAG';
import { white } from 'material-ui/styles/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

type RAGRaterChipProps = {
    rag: RAG
}

function selectRAGColor(rag: RAG){
    switch(rag){
        case RAG.Red : 
           return <div><Chip label="HIGH" variant="filled"  sx={{
            backgroundColor: '#C70000',
            fontWeight: '600',
            paddingTop: '3px',
            height: '25px',
            '& .MuiChip-label': {
              color: 'white'
            }
          }}/></div>
        case RAG.Amber :
            return  <div><Chip label="MED" variant="filled" sx={{backgroundColor:'#FFCC00', paddingTop: '3px', fontWeight: '600', height: '25px'}} /></div>
        case RAG.Unscored :
        case RAG.Green :
            return <div><Chip label="LOW" variant="filled"  sx={{
                backgroundColor:'#067A1D',
                 fontWeight: '600',
                 paddingTop: '3px',
                 height: '25px',
                '& .MuiChip-label': {
                  color: 'white'
            }}}/></div>
    }
}

export function RAGRaterChip({rag} : RAGRaterChipProps) {
    
    return selectRAGColor(rag);
}