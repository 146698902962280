import axios from 'axios';

axios.defaults.baseURL = "https://api.clinitouch.com/"

const axiosInstance = axios.create({
    baseUrl: 'https://api.clinitouch.com/',
    headers:{
                'CF-Access-Client-Id': 'fecabe4a41073dda14bcf7e88859a121.access',
                'CF-Access-Client-Secret': 'e889722531be09a137a1886a8e9194ad7c6e527763b95500429a16eedcce5af3'
            }
})

export const setAuthToken = (token) => {
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  };

export default axiosInstance;