import React, { useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';


import { createContext, useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance, { setAuthToken } from '../../service/apiBase';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import { FirebaseProvider }  from "../../components/Firebase/FirebaseContext"
import { useFirebase } from '../Firebase/FirebaseContext';

type AppProps = {
    children: any;
  };
  
  export const UserToken = React.createContext(null);
const TokenContext = createContext("");

export const useAuthToken = () => {
  return useContext(TokenContext);
};

  

export const Auth0ProviderWithToken = ({ children }: AppProps)  => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState("");
  
    const { app, analytics } = useFirebase();
  
  
    useEffect(() => {
      const fetchToken = async () => {
        if (isAuthenticated) {
          try {
            const token = await getAccessTokenSilently();
            setToken(token);
            setAuthToken(token);
  
            const analytics = getAnalytics();
            logEvent(analytics, 'clinician_login');
            
          } catch (error) {
            console.error("Error fetching token: ", error);
          }
        }else {
          setToken("");
          setAuthToken(null);
        }
      };
  
      fetchToken();
    }, [isAuthenticated, getAccessTokenSilently]);
  
    return (
      <TokenContext.Provider value={token}>
        {children}
      </TokenContext.Provider>
    );
  };
