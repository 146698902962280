import React, { useState, useEffect, useMemo, useCallback, useContext} from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Card, CardContent, Button, Stack, Alert, FormHelperText } from '@mui/material';
import { useTranslation } from "react-i18next";
import {useNavigate} from 'react-router-dom';
import { timeConverter } from '../../../service/timeConverter';
import { updateData, sendData } from '../../../service/dataService';
import { useForm, Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import { Gender, Patient } from '../../../models/Patient';
import { HumanName } from '../../../models/HumanName';
import { ContactPoint } from '../../../models/ContactPoint';
import { DateField } from '@mui/x-date-pickers';
import { FormInputText } from './FormInputText';
import styles from '../../../styles/Patient.module.css';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { Identifier } from '../../../models/Identifier';
import { PatientListItem, Mode } from '../../../models/PatientListItem';
import { throttle } from 'lodash';
import { getAnalytics, logEvent } from "firebase/analytics";
import { useFirebase } from '../../Firebase/FirebaseContext';
import PatientsContext from '../../../contexts/PatientContext';

type PatientProps = {
    patientId: string,
    mode: Mode
}

export function PatientDetails({patientId,mode} : PatientProps) {

    const { ToDateTime } = timeConverter();
    const {patient, setPatient, message, setMessage} = useContext(PatientsContext);
    const defaultValues = {
        firstName: patient?.firstName ?? "",
        lastName: patient?.lastName ?? "",
        dateOfBirth: patient?.unformattedDateOfBirth ? DateTime.fromFormat(patient?.unformattedDateOfBirth,"yyyy-MM-dd") : null,
        mobileNumber: patient?.phoneNumber ?? "",
        patientIdentifier: patient?.patientIdentifier ?? "",
        gender: patient?.gender as Gender ?? "unknown"
    };
    
    const [error,setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const methods = useForm({defaultValues});
    const { i18n, t} = useTranslation();
    const { app, analytics } = useFirebase();

    async function onSubmit() {
        let patient = constructFhirPatient();
        try {
        if (mode == Mode.Create) {
            logEvent(analytics, "clinician_create_patient")
            await createPatientThrottled(patient);
        } else {
            logEvent(analytics, "clinician_edit_patient")
            await editPatient(patient);
        }
    } catch (err: any) {
        if (err?.response?.data?.errorcode) {
            setError(t(err.response.data.errorcode));
        } else {
            setError(t("error.something-went-wrong"));
        }
    } 
    }

    function cancel() {
        navigate('/patient')
    }

    const createPatientThrottled = useCallback(throttle(createPatient,15000,{leading:true,trailing:false}),[]);
        
    async function createPatient(updPatient: fhir4b.Patient) {
        await sendData('patient','',updPatient);
        navigate('/patient');
    };

    async function editPatient(updPatient: fhir4b.Patient) {
        await updateData('patient','','',updPatient);
        setMessage(t("patient.patient-saved"));
        updatePatientList(patient, updPatient);
    }

    async function updatePatientList(patient: PatientListItem | undefined, updPatient: fhir4b.Patient) {
        patient!.firstName = methods.getValues('firstName').trim();
        patient!.lastName = methods.getValues('lastName').trim();
        patient!.unformattedDateOfBirth = methods.getValues('dateOfBirth')!.toFormat("yyyy-MM-dd");
        patient!.phoneNumber = methods.getValues('mobileNumber').trim();
        patient!.patientIdentifier = methods.getValues('patientIdentifier')?.trim();
        patient!.gender = methods.getValues('gender');
        setPatient({...patient!});
    }

    function constructFhirPatient(): fhir4b.Patient {

        let editPatient = new Patient();

        editPatient.active = true;
        let name = new HumanName();
        editPatient.id = patient?.fhirId;
        editPatient.name = [name];
        name.family = methods.getValues('lastName')?.trim();
        name.given = [methods.getValues('firstName')?.trim()];
        name.text = `${methods.getValues('firstName')?.trim()} ${methods.getValues('lastName')?.trim()}`
        editPatient.birthDate = methods.getValues('dateOfBirth')!.toFormat("yyyy-MM-dd");
        editPatient.gender = methods.getValues('gender') as Gender;

        if (methods.getValues('patientIdentifier')) {
            let identifier = new Identifier();
            identifier.use = "secondary";
            identifier.value = methods.getValues('patientIdentifier')?.trim();
            editPatient.identifier = [identifier];
        }

        let sms = new ContactPoint();
        editPatient.telecom = [sms];
        sms.system = "sms";
        sms.use = "mobile";
        sms.value = methods.getValues('mobileNumber')?.trim();
        return editPatient;
    }

    return (
        <>
        <h5>{mode == Mode.Create?t('patient.create.header'):t('patient.edit.header')}</h5>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
            <Stack direction="column" className={styles.patientform}>
                
                <FormInputText name="firstName" label={t('patient.first-name')+"*"}
                className={styles.input}
                    rules={{
                        required:
                            {value:true, 
                             message:t('patient.errors.first-name-is-mandatory')},
                        maxLength: {
                            value:30,
                            message:t('patient.errors.first-name-less-than-30')
                        }
                    }} 
                    control={methods.control} />

                <FormInputText name="lastName" 
                    className={styles.input}
                    label={t('patient.last-name')+"*"} 
                    rules={{
                        required: {
                            value: true,
                            message: t('patient.errors.last-name-is-mandatory')
                        },
                        maxLength: {
                            value:30,
                            message:t('patient.errors.last-name-less-than-30')
                        }                        
                    }}
                    control={methods.control} />

                <Controller
                    control={methods.control}
                    name="dateOfBirth"
                    rules={{
                        required: {
                            value:true,
                            message:t('patient.errors.date-of-birth-is-mandatory')
                        }
                    }}
                    render={({ field: { onChange, onBlur, value, ref }, fieldState: {error} }) => (
                        <FormControl>
                        <DateField
                        className={styles.input}
                        label={t('patient.date-of-birth')+"*"}
                        margin="dense"
                        disableFuture
                        onChange={onChange} // send value to hook form
                        onBlur={onBlur} // notify when input is touched/blur
                        value={value}
                        InputLabelProps={{
                            style: {
                                color: error ? '#d32f2f' : 'rgba(0, 0, 0, 0.54)',
                            },
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)', // Default border
                                },
                                '&:hover fieldset': {
                                    borderColor: error ? '#d32f2f' : 'black', // Hover border
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: error ? '#d32f2f' : 'blue', // Error border color
                                },
                            },
                        }}
                        />
                        <FormHelperText sx={{ marginTop: '18px', marginLeft: '0', color: '#d32f2f' }}>{error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />                

                <Controller 
                    control={methods.control}
                    name="mobileNumber"
                    rules={{
                        required: {
                            value: true,
                            message:t('patient.errors.mobile-number-is-mandatory')
                        }
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <FormControl margin="normal">
                            <label>{t('patient.mobile-number')+"*"}</label>
                            <PhoneInput value={value} onChange={onChange} forceDialCode defaultCountry="gb" />
                        </FormControl>
                    )}
                />

                <FormInputText name="patientIdentifier" 
                    className={styles.input}
                    label={t('patient.patient-identifier')} 
                    rules={{}}
                    control={methods.control} />

                <Controller 
                    control={methods.control}
                    name="gender"
                    render={({ field: { onChange, value, ref}}) => (
                        <FormControl margin="normal">
                        <label id="gender-label">{t('patient.gender-label')}</label>
                        <Select id="gender"
                            labelId="gender-label"
                            value={value}
                            margin="dense"
                            onChange={onChange}
                        >
                            <MenuItem value="male">{t('patient.gender.male')}</MenuItem>
                            <MenuItem value="female">{t('patient.gender.female')}</MenuItem>
                            <MenuItem value="other">{t('patient.gender.other')}</MenuItem>
                            <MenuItem value="unknown">{t('patient.gender.unknown')}</MenuItem>
                        </Select>
                        </FormControl>
                    )} />

                {message && <Alert severity="success">{message}</Alert>}
                {error && <Alert severity="error">{error}</Alert>}
                <Stack direction="row">
                    { mode == Mode.Create ? (
                        <Stack direction="row">
                            <Button id="cancel" disableRipple variant="outlined" onClick={cancel} size="medium" className={styles.cancelbutton}>{t('command.cancel')}</Button>
                            <Button id="add-patient" disableRipple size="medium" variant="contained" className={styles.actionbutton} type="submit">{t('patient.add-new-patient')}</Button>
                        </Stack>
                    ) : (
                        <Stack direction="row">
                            <Button id="save-patient" size="medium" variant="contained" className={styles.actionbutton} type="submit">{t('command.save-changes')}</Button>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </form>
        </>
    )
}
