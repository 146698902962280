import { useEffect } from 'react';

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";


export function LoginPage() {

  const navigate = useNavigate();

  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {

    const analytics = getAnalytics();
    logEvent(analytics, 'login');

    if (isAuthenticated) {
      const analytics = getAnalytics();
      logEvent(analytics, 'test');
      navigate('/task');
    }else{
      loginWithRedirect()
    }
  }, [isAuthenticated, loginWithRedirect, navigate]);

    return <div><h1>Loading...</h1></div>
}
