import React, {useState, useEffect, useContext} from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import styles from '../../styles/LoginPage.module.css';
import { useTranslation } from 'react-i18next';
import { fetchData } from '../../service/dataService';
import { useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';

import { DateTime } from 'luxon';
import { timeConverter } from '../../service/timeConverter';

import { useAuthToken } from '../../components/Auth0/Auth0ProviderWithToken';
import { PatientListItem } from '../../models/PatientListItem';

import { getFirstName, getLastName, getBirthday, getIdentifier, getPhoneNo } from '../../helpers/patientHelper';

export default function PatientList() {

  const { i18n, t} = useTranslation();
  const navigate = useNavigate();
  const token  = useAuthToken();

  const [users, setUsers] = useState<PatientListItem[]>([]);

  const { ToDateTime } = timeConverter();

  const customStyles = {
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'rgba(242,224,251,0.3)', 
      '& .MuiDataGrid-cell': {
        fontWeight: '600',
      },
    },
  };

  useEffect(() => {
      const fetchUsers = async () => {
          try {
              const responseData = await fetchData('crud','Patient', '{}');

              let mappedData: PatientListItem[] = responseData.map((patient: fhir4b.Patient) => ({
                id: patient._id,
                firstName: getFirstName(patient),
                lastName: getLastName(patient),
                dateOfBirth: getBirthday(patient),
                unformattedDateOfBirth: patient.birthDate,
                gender: patient.gender,
                phoneNumber: getPhoneNo(patient),
                fhirId: patient.id,
                condition: "TBC",
                pathway: "TBD",
                patientIdentifier: getIdentifier(patient)
            }));

            mappedData.sort((a, b) => a.firstName.localeCompare(b.firstName));
              setUsers(mappedData);

          } catch (error) {
              console.error('Error fetching data: ', error)
          }
      }
      if(token){
        fetchUsers();
      }
  }, [token]);

  function createPatient() {
    navigate('/patient/create');
  }

  const handleRowClick = (params: any) => {
    navigate('/patientView', { state: {patientId : params.row.id}});
  };

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: t('patient.first-name'), flex: 1 },
    { field: 'lastName', headerName: t('patient.last-name'), flex: 1 },
    { field: 'dateOfBirth', headerName: t('patient.date-of-birth'), flex: 1 },
    { field: 'patientIdentifier', headerName: t('patient.patient-id'), flex: 1 },
    {
      field: 'viewButton',
      headerName: '',
      renderCell: () => (
        <div className={styles.viewButtonWrapper}>
          <button className="viewButton">{t("command.view")}</button>
        </div>
      ),
      width: 60,
      hideable: false,
    },
  ];

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }} >
        <h3>{t("patient.header")}</h3>
        <Button color="primary" size="medium" variant="contained" onClick={() => createPatient()}>{t("patient.add-patient")}</Button>
      </Box>
      <div className='table-container'>
      <h5 className="table-container-header">{t("patient.subheader")}</h5>

        <DataGrid
          rows={users}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20]}
          getRowId={(row) => row.id}
          onRowClick={handleRowClick}
          sx={customStyles}
        />
      </div>
    </div>
  );      
}
