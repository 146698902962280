
import { PlanDefinition } from '../models/PlanDefinition';
import { CarePlan } from '../models/CarePlan';
import { Period } from '../models/Period';
import { CarePlan_activity, CarePlan_activityDetail, ScheduledTiming, ScheduledTiming_repeat } from '../models/CarePlan_activity';
import { Subject } from '../models/Subject';
import { DateTime } from 'luxon';
import { timeConverter } from './timeConverter';
import {Realm} from 'realm';

export function CarePlanService(userId: string) {

    const {ToString, ToDateTime } = timeConverter();

    const constructBasic = (carePlan: CarePlan, pathway: PlanDefinition) => {
        carePlan._id = new Realm.BSON.ObjectId().toString();
        carePlan.instantiatesCanonical = [pathway?.url!];
        carePlan.period = new Period();
        carePlan.period.start = ToString(DateTime.now());
        carePlan.resourceType = "CarePlan";
        carePlan.status = "active";
        carePlan.created = ToString(DateTime.now());
        carePlan.subject = new Subject();
        carePlan.subject.reference = userId;
        carePlan.title = pathway?.title;
    
        carePlan.activity = new Array<CarePlan_activity>();
        pathway?.action.forEach(act => {
            let activity = new CarePlan_activity();
            activity.detail = new CarePlan_activityDetail();
            activity.detail.instantiatesCanonical = [act.definitionCanonical!];
            activity.detail.scheduledTiming = new ScheduledTiming();
            activity.detail.scheduledTiming.repeat = new ScheduledTiming_repeat();
    
            carePlan.activity.push(activity);
        })
      }

    return {
        constructBasic
    }
}