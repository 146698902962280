import { Card, CardContent, Stack, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from "react-i18next";
import { PlanDefinition } from '../../../models/PlanDefinition';
import { PathwayActivityRow } from './PathwayActivityRow';
import {DateTime} from 'luxon';
import { PlanDefinition_action } from '../../../models/PlanDefinition_action';
import { CarePlan } from '../../../models/CarePlan';
import { timeConverter } from '../../../service/timeConverter';

type PathwayProps = {
    pathway : PlanDefinition,
    carePlan : CarePlan,
    refresh: any
}

export function PathwayDetails(response : PathwayProps) {

    const { i18n, t} = useTranslation();
    const { ToString, ToDateTime } = timeConverter();

    function setStartDate(value: DateTime | null) {
        response.carePlan!.period.start = ToString(value!)
    }

    return (
    <Card>
        <CardContent>
            <Stack direction="column">
                <label>{t('pathway.title')}</label>
                <h5>{response.pathway.title}</h5>
                <br />
                <label>{t('pathway.start-date')}</label>
                <DatePicker value={ToDateTime(response.carePlan?.period.start)} onChange={(newValue) => setStartDate(newValue)}></DatePicker>
                <br />
                <table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('pathway.item.title')}</TableCell>
                            <TableCell>{t('pathway.item.content-type')}</TableCell>
                            <TableCell>{t('pathway.frequency.title')}</TableCell>
                            <TableCell>{t('common.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {response.pathway.action.map((action:PlanDefinition_action) => (
                            <PathwayActivityRow action={action} carePlan={response.carePlan} refresh={response.refresh}></PathwayActivityRow>
                        )
                        )}
                    </TableBody>
                </table>
            </Stack>

        </CardContent>
    </Card>

    )
}