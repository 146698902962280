import { DateTime } from 'luxon';
import { timeConverter } from '../service/timeConverter';

const { ToDateTime } = timeConverter();

function getFirstName(patient: fhir4b.Patient) : string {
    var name = patient?.name?.at(0)
    var first = name?.given?.at(0)

    return first ?? ""
  }

  function getLastName(patient: fhir4b.Patient): string {
    var name = patient?.name?.at(0)
    return name?.family ?? ""
  }

  function getBirthday(patient: fhir4b.Patient): string {
    if(patient?.birthDate){
      var luxonDate = ToDateTime(patient.birthDate);
      return luxonDate.toLocaleString(DateTime.DATE_MED);
    }
    return ""
  }

  function getIdentifier(patient: fhir4b.Patient): string {
      return patient?.identifier?.at(0)?.value ?? ""
  }

  function getPhoneNo(patient: fhir4b.Patient): string {
    return patient?.telecom?.at(0)?.value ?? "";
  }

  export { 
    getFirstName,
    getLastName,
    getBirthday,
    getIdentifier,
    getPhoneNo
  }

