import styles from '../../styles/TaskPage.module.css';
import  PatientList from '../../components/Patient/PatientList';
import { AddPatientPathway } from '../../components/Patient/Pathway/AddPatientPathway';
import { useLocation } from 'react-router-dom';

export function AddPatientPathwayPage() {
  const location = useLocation();
  const patient = location.state.patient;
  const carePlan = location.state.carePlan;
  const refresh = () => {}

  return (
    <div className={styles.container}>
       <AddPatientPathway patient={patient} refresh={refresh} carePlan={carePlan} />
    </div>
  );
}
