import React, { ComponentType } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  component: ComponentType<any>;
  [key: string]: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, ...args }) => {

  const { isAuthenticated, isLoading } = useAuth0();

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <div><h1>Loading...</h1></div>,
  });

  return  <Component {...args} />
};

export default ProtectedRoute;