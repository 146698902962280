class SpiritFhirExtensions {
    scoreQuestionnaireResponse = 'https://spirit-health.com/Questionnaire/score-questionnaire-response-extension';
    scoreAnswer = 'https://spirit-health.com/Questionnaire/score-answer-extension';
    reviewedDate = 'https://www.spirit-health.co.uk/fhir/QuestionnaireResponse/reviewed-date';
    firstName = 'https://clinitouch.com/fhir/Patient/first-name-extension';
    lastName = 'https://clinitouch.com/fhir/Patient/last-name-extension';
}

const spiritFhirExtensions = new SpiritFhirExtensions()

export { spiritFhirExtensions }