import styles from '../../styles/TaskPage.module.css';
import { PatientView } from '../../components/Patient/PatientView'
import { useLocation } from 'react-router-dom';


export function PatientViewPage() {
  const {state} = useLocation();

  return (
    <div className={styles.container}>
       <PatientView patientId = {state.patientId} />
    </div>
  );
}