export class PatientListItem {
    id!: string;
    firstName!: string;
    lastName!: string;
    dateOfBirth!: string;
    unformattedDateOfBirth!: string;
    gender!: string;
    fhirId!: string;
    phoneNumber!: string;
    condition!: string;
    pathway!: string;
    patientIdentifier!: string;
  }

  export enum Mode { 
    Create,
    Edit
  }