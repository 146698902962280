
import { useAuth0 } from '@auth0/auth0-react';
import { LANGUAGES } from '../../constants/languages';
import { NavBarMenu } from '../../components/NavBar/NavBarMenu'
import styles from '../../styles/NavBar.module.css';
import { useTranslation } from 'react-i18next';
import { useFirebase } from '../Firebase/FirebaseContext';
import { getAnalytics, logEvent } from "firebase/analytics";

import logo from '../../assets/Purple-Horizontal.png';
/**
 * Nav bar providing a button for logging out.
 */
export function NavBar() {
  const { logout } = useAuth0();
  const { i18n, t} = useTranslation();
  const { app, analytics } = useFirebase();

  const onChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  }

  const cascadeLogOut = () => {
    logEvent(analytics, 'clinician_logout');
    logout();
  }

  return (
    <nav className={styles.nav}>
        <img 
          alt='clinitouch'
          className={styles.logo}
          src={logo}
          width={150}
        />

      <NavBarMenu/>
      <button className={styles.button} onClick={cascadeLogOut}>
        {t('command.logout')}
      </button>

    </nav>
  );
}
