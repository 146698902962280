////////////////////////////////////////////////////////////////////////////
//
// Copyright 2023 Realm Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
////////////////////////////////////////////////////////////////////////////
import React, { useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import { AuthenticatedApp } from './AuthenticatedApp';
import { ErrorPage } from './pages/ErrorPage';
import { LoginPage } from './pages/LoginPage';
import { TasksPage } from './pages/TasksPage';
import { PatientListPage } from './pages/Patient/PatientListPage';
import { PatientViewPage } from './pages/Patient/PatientViewPage';
import { QuestionnaireViewPage } from './pages/QuestionnaireViewPage';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import auth0Config from './auth0/auth0-config.json';

import styles from './styles/App.module.css';
import { getUserLocales } from 'get-user-locale';
import { EditPathwayPage } from './pages/Patient/Pathway/EditPathwayPage';
import { AddPatientPathwayPage } from './pages/Patient/AddPatientPathwayPage';
import { CreatePatientPage } from './pages/Patient/Create/CreatePatientPage';

import  ProtectedRoute  from './components/Auth0/ProtectedRoute'

import { FirebaseProvider }  from "./components/Firebase/FirebaseContext"
import { Auth0ProviderWithToken } from "./components/Auth0/Auth0ProviderWithToken"

import { PatientListItem } from './models/PatientListItem';
import PatientsContext from './contexts/PatientContext';


const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    element: <ProtectedRoute component={AuthenticatedApp} />,
    children: [
      {
        path: 'task',
        element: <ProtectedRoute component={TasksPage} />
      },
      {
        path: 'questionnaire',
        element: <ProtectedRoute component={QuestionnaireViewPage} />
      },
      {
        path: 'patient',
        element: <ProtectedRoute component={PatientListPage} />
      },
      {
        path: 'patientView',
        element: <ProtectedRoute component={PatientViewPage} />
      },
      {
        path: 'pathway/edit',
        element: <ProtectedRoute component={EditPathwayPage} />
      },
      {
        path: 'pathway/add',
        element: <ProtectedRoute component={AddPatientPathwayPage} />
      },
      {
        path: 'patient/create',
        element: <ProtectedRoute component={CreatePatientPage} />
      }
    ]
  }
]);


function App() {
  const locales = getUserLocales({useFallbackLocale: true, fallbackLocale: 'en-gb'});
  return (
    <div className={styles.container}>
      <Auth0Provider
        domain={auth0Config.DOMAIN}
        clientId={auth0Config.CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin+"/task",
          audience: auth0Config.AUDIENCE
        }}
      >
        <Auth0ProviderWithToken>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locales[0]}>
            <FirebaseProvider>
              <RouterProvider router={router} />
            </FirebaseProvider>
          </LocalizationProvider>
        </Auth0ProviderWithToken>
      </Auth0Provider>
    </div>
  );
}

export default App;
