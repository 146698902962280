import { DateTime } from 'luxon';
import { timeConverter } from '../service/timeConverter';
import { RAG, ragRanges } from '../constants/RAG'

import { spiritFhirExtensions } from "../constants/spiritFhirExtensions";

const { ToDateTime } = timeConverter();

 const getFirstName = function(response: fhir4b.QuestionnaireResponse) : String {
    var subjectExtensions = response.subject?.extension?.find(x => x?.url === spiritFhirExtensions.firstName) as fhir4b.Extension
    return subjectExtensions?.valueString ?? ""
 }

 const getLastName = function(response: fhir4b.QuestionnaireResponse) : String {
  var subjectExtensions = response.subject?.extension?.find(x => x?.url === spiritFhirExtensions.lastName) as fhir4b.Extension
  return subjectExtensions?.valueString ?? ""
}

const  extractReviewedDate = function(response:  fhir4b.QuestionnaireResponse) : String {
    var reviewedExtension = response?.extension?.find(x => x?.url === spiritFhirExtensions.reviewedDate) as fhir4b.Extension
    if(!reviewedExtension?.valueDateTime) {
      return "";
    }
    var luxonDate = ToDateTime(reviewedExtension?.valueDateTime)
    return luxonDate.toLocaleString(DateTime.DATETIME_MED);
  }

  function extractCompletedDateFormatted(response: fhir4b.QuestionnaireResponse) : String { 
    if (!response?.authored) return "";
    var luxonDate = ToDateTime(response.authored);
    return luxonDate.toLocaleString(DateTime.DATETIME_MED);
  }

  function extractCompletedDate(response: fhir4b.QuestionnaireResponse) : string { 
    if (!response?.authored) return "";
    return response?.authored?.toString() ?? ""
  }


const getQuestionnaireResponseRAG = function(response:  fhir4b.QuestionnaireResponse) : RAG {
  var score = getQuestionnaireResponseScore(response);

  return ragScorer(score);
}

const getQuestionnaireResponseScore = function(response:  fhir4b.QuestionnaireResponse) : number | undefined {
  var questionnaireScore = response.extension?.find(x => x.url == spiritFhirExtensions.scoreQuestionnaireResponse)

  var score = questionnaireScore?.valueInteger;

  return ragScoreFlatterner(score);
}

const answerQuestionnaireResponseRAG = function(responseItem: fhir4b.QuestionnaireResponseItem) : RAG {
    var answerExtensions = responseItem.answer?.map(x => x.extension).flat(1); 
    var scoreExtensions = answerExtensions?.filter(x => x?.url === spiritFhirExtensions.scoreAnswer)
    
    var score = scoreExtensions?.reduce((sum, value) => {
      return sum + (value?.valueInteger ?? 0)
    }, 0);

    return ragScorer(score)
}

// This function is created to allow multi coloumn filtering 
// All rags need to have the same score to then be able to then filter on date
const ragScoreFlatterner = function(score: number | undefined): number{
  if(!score){
    return 0
  }

  if(score >= 100){
    return 100
  }else if(score >= 50){
    return 50
  }else{
    return 0
  }
}

const ragScorer = function(score: number | undefined): RAG{
  if(!score){
    return RAG.Unscored;
  }else if(score >= ragRanges.redMin){
    return RAG.Red
  }else if(score < ragRanges.redMin && score >= ragRanges.amberMin){
    return RAG.Amber
  }else{
    return RAG.Green
  }
}

const getQuestionnaireTitle = (questionnaireResponse: fhir4b.QuestionnaireResponse): String | undefined => {
  const questionnaireRefArray = questionnaireResponse?.basedOn as Array<fhir4b.Reference>
  var reference = questionnaireRefArray[0] as fhir4b.Reference
  return reference?.display;
}

const getPatientId = (questionnaireResponse: fhir4b.QuestionnaireResponse): String | undefined => {
  return questionnaireResponse?.subject?.id?.split('Patient/').pop()
}

export { 
  extractReviewedDate, 
  extractCompletedDate, 
  extractCompletedDateFormatted,
  getQuestionnaireResponseRAG, 
  getQuestionnaireResponseScore, 
  answerQuestionnaireResponseRAG,
  getQuestionnaireTitle,
  getPatientId,
  getFirstName,
  getLastName
 }
