import { DateTime } from 'luxon';

export function timeConverter() {

    const ToString = (date: DateTime) => {
        return date.toISO()!;
    }

    const ToDateTime = (fhirDate : string ) => {
        return DateTime.fromISO(fhirDate);
    }

    return {ToString, ToDateTime};
}