import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styles from '../../styles/TaskItem.module.css';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { PatientPathway } from './Pathway/PatientPathway';

import {useNavigate} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from 'react-router-dom';
import { PatientListItem } from '../../models/PatientListItem';
import { EditPatient } from './Create/EditPatient';
import PatientsContext from '../../contexts/PatientContext';
import { useEffect } from 'react';
import { useAuthToken } from '../Auth0/Auth0ProviderWithToken';
import { fetchData } from '../../service/dataService';
import { getFirstName, getLastName, getPhoneNo, getIdentifier } from '../../helpers/patientHelper';

type PatientProps = {
    patientId: string
}

export function PatientView({patientId} : PatientProps) {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ patient, setPatient ] = React.useState<PatientListItem>();
  const [ message, setMessage ] = React.useState<string | undefined>();
  const [tabIndex, setTabIndex] = React.useState(searchParams.get("tab") ?? '1');
  const token  = useAuthToken();

  useEffect(() => {
    const fetchUser = async () => {
        try {
            const responseData = await fetchData('crud','Patient', `{"_id":{"$oid":"${patientId}"}}`);

            let mappedData: PatientListItem[] = responseData.map((patient: fhir4b.Patient) => ({
              id: patient._id,
              firstName: getFirstName(patient),
              lastName: getLastName(patient),
              unformattedDateOfBirth: patient.birthDate,
              fhirId: patient.id,
              gender: patient.gender,
              phoneNumber: getPhoneNo(patient),
              patientIdentifier: getIdentifier(patient)
          }));

          if (mappedData) {
            setPatient(mappedData.at(0)!);
          }

        } catch (error) {
            console.error('Error fetching data: ', error)
        }
    }
    if(token){
      fetchUser();
    }
}, [token]);

  const { i18n, t} = useTranslation();
  const navigate = useNavigate();


  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabIndex(newValue);
    navigate(`?tab=${newValue}`, { state: {patientId : patientId}});
  };

  function back() {
    navigate('/patient');
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));


  return (
        <div className={styles.tasks}>
            <PatientsContext.Provider value={{patient, setPatient, message, setMessage}}>
                <Button size="small" onClick={() => back()}><ArrowBackIcon/> {t("command.back-all-patients")}</Button>
                <div><h3>{patient?.firstName} {patient?.lastName}</h3></div>
                <br /><br />
        <Item>
            <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="patient tabs">
                    <Tab label={t("patient.details")} value="1" />
                    <Tab label={t("patient.pathways")} value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                    <EditPatient patientId={patientId}/>
                </TabPanel>
                <TabPanel value="2">
                    <PatientPathway patientId={patientId}/>
                </TabPanel> {/*temp text so not added to translate*/}
            </TabContext>
            </Box>
        </Item>
        </PatientsContext.Provider>
        </div>
  );
}