import styles from '../styles/TaskPage.module.css';
import  TaskList from '../components/Questionnaire/TaskList';

export function TasksPage() {
  
  return (
    <div className={styles.container}>
       <TaskList />
    </div>
  );
}
