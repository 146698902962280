import { Chip, Grid } from '@mui/material';
import { RAG } from '../../constants/RAG';
import { useTranslation } from 'react-i18next';

type RAGRaterChipProps = {
    rag: RAG
}

export function RAGRaterAnswerChip({rag} : RAGRaterChipProps) {
    const { i18n, t} = useTranslation();

    function selectRAGColor(rag: RAG){
        switch(rag){
            case RAG.Red : 
               return <div>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <span className='title'>{t('questionnaireResponse.concern')}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <Chip label="HIGH" variant="filled"  sx={{
                            backgroundColor: '#C70000',
                            fontWeight: 'bold',
                            height: '25px',
                            paddingTop: '3px',
                            '& .MuiChip-label': {
                            color: 'white'
                            }
                        }}/>
                    </Grid>
                </Grid>
                
                </div>
            case RAG.Amber :
                return  <div>{t('questionnaireResponse.concern')}</div>
            case RAG.Green :
            case RAG.Unscored :
                return <div></div>
        }
    }
    
    return selectRAGColor(rag);
}