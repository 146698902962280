import React, {Dispatch, useState} from 'react';
import { PatientListItem } from '../models/PatientListItem';

interface UserControl {
    patient: PatientListItem | undefined,
    setPatient: Dispatch<PatientListItem>,
    message: string | undefined,
    setMessage: Dispatch<string | undefined>
}

const PatientsContext = React.createContext({} as UserControl);


export default PatientsContext;