const enum RAG {
    Red,
    Amber,
    Green,
    Unscored
}
class RAGRanges {
    public redMin: number = 100;
    public amberMin: number = 50;
}

const ragRanges = new RAGRanges();

export {RAG, ragRanges}