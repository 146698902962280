import styles from '../styles/TaskPage.module.css';
import { QuestionnaireView } from '../components/Questionnaire/QuestionnaireView';
import { useLocation } from 'react-router-dom';

type QuestionnaireTaskProps = {
    responseId: number
}

export function QuestionnaireViewPage() {
  const {state} = useLocation();

  return (
    <div className={styles.container}>
       <QuestionnaireView questionnaireResponse = {state.questionnaireResponse} />
    </div>
  );
}