import { CarePlan } from '../../../models/CarePlan';
import { Alert, Card, CardContent, Button, Stack, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useTranslation } from "react-i18next";
import { timeConverter } from '../../../service/timeConverter';
import { PlanDefinition } from '../../../models/PlanDefinition';
import { PlanDefinition_action } from '../../../models/PlanDefinition_action';
import { PathwayActivityRow } from './PathwayActivityRow';
import { User } from '../../../models/User';
import { useNavigate } from 'react-router-dom';
import styles from '../../../styles/TaskItem.module.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { fetchData, updateData } from '../../../service/dataService';
import { useAuthToken } from '../../../components/Auth0/Auth0ProviderWithToken';
import { ScheduledTiming } from '../../../models/CarePlan_activity';
import { useFirebase } from '../../Firebase/FirebaseContext';
import { getAnalytics, logEvent } from "firebase/analytics";

type EditPathwayProps = {
    carePlan : CarePlan,
    patientId : string
}

export function EditPathway(response : EditPathwayProps) { 
    const { t} = useTranslation();
    const { ToDateTime } = timeConverter();
    const navigate = useNavigate();

    const defaultValues = {pathway: undefined};
    const methods = useForm({defaultValues});
    const { app, analytics } = useFirebase();

    const token  = useAuthToken();

    const [ pathway, setPathway ] = useState<PlanDefinition[]>();
    useEffect(() => {
      const fetchPathways = async () => {
          try {
              const responseData = await fetchData('crud','PlanDefinition', `{"url":"${response?.carePlan?.instantiatesCanonical[0]}"}`);
              setPathway(responseData);
          } catch (error) {
              console.error('Error fetching data: ', error)
          }
      }
      if(token){
        fetchPathways();
      }
  }, [token]);

    const navigateToViewPage = () => {
        try {
          navigate(-1);
        }catch(error){
            console.error('Failed to navigate to view page', error)
        }
      }

      const saveCarePlan = () => {
        logEvent(analytics, "clinician_edit_careplan")
        let id = response.carePlan._id;
        response.carePlan._id = undefined;
        updateData("crud","CarePlan",id!,response.carePlan);
        navigateToViewPage();
      }   
      
      const onModalSubmit = () => {

        let hasError = false;
        response.carePlan.activity.forEach(act => {
          let freq = act.detail.scheduledTiming;
          if (freq.code !== undefined && freq.code?.text === ScheduledTiming.continuous) {
            return;
          }
          if (freq.repeat === undefined || freq.repeat.period === undefined || freq.repeat.period === 0 || freq.repeat.periodUnit === undefined) {
            methods.setError("pathway",{type:"custom", message:"Pathway frequency must be defined"});
            hasError = true;
          }
        })
        if (!hasError) saveCarePlan();        
      }

      const clearErrors = () => {
        methods.clearErrors();
      }

    return (
        <div>
          
            <div className={styles.tasks}>
                    <Card>
                        <CardContent>
                        <h3>{t("pathway.editing-pathway")}</h3>
                            <Card>
                                <CardContent>
                                <Card>
        <CardContent>
            <Stack direction="column">
                <label>{t('pathway.title')}</label>
                <h5>{response.carePlan.title}</h5>
                <br />
                <label>{t('pathway.start-date')}</label>
                <h5>{ToDateTime(response.carePlan.period.start).toLocaleString()}</h5>
                <br />
                {(pathway?.length ?? 0) > 0 &&
                <table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('pathway.item.title')}</TableCell>
                            <TableCell>{t('pathway.item.content-type')}</TableCell>
                            <TableCell>{t('pathway.frequency.title')}</TableCell>
                            <TableCell>{t('common.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pathway![0].action.map((action:PlanDefinition_action) => (
                            <PathwayActivityRow action={action} carePlan={response.carePlan} refresh={() => {}}></PathwayActivityRow>
                        )
                        )}
                    </TableBody>
                </table>
              }
            </Stack>

        </CardContent>
    </Card>
                                </CardContent>
                            </Card>
                            <br />
                            <form onSubmit={methods.handleSubmit(onModalSubmit)}>
                            { methods.formState.errors.pathway && <Alert severity="error">{t('pathway.errors.frequency-not-set')}</Alert>}
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" onClick={navigateToViewPage}>{t("command.cancel")}</Button>
                                <Button variant="contained" type="submit">{t("command.save")}</Button>
                            </Stack>
                            </form>
                    </CardContent>
                </Card>
            <br />
        </div>
    </div>

);
}