import { useState, useEffect } from 'react';
import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchData } from '../../service/dataService';
import { RAGRaterChip } from "./RAGRaterChip";
import styles from '../../styles/TaskPage.module.css';
import { DateTime } from 'luxon';
import { timeConverter } from '../../service/timeConverter';
import { useAuthToken } from '../Auth0/Auth0ProviderWithToken';
import { useFirebase } from '../Firebase/FirebaseContext';
import { 
  extractReviewedDate, 
  extractCompletedDate, 
  getQuestionnaireResponseRAG, 
  getQuestionnaireResponseScore, 
  getPatientId,
  getQuestionnaireTitle,
  getFirstName,
  getLastName 
} from '../../helpers/questionnaireResponseHelper';
import { Patient } from '../../models/Patient';
import { getAnalytics, logEvent } from "firebase/analytics";
// Define your custom styles
const customStyles = {
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'rgba(242,224,251,0.3)', // Change this color to your desired hover color
    '& .MuiDataGrid-cell': {
      fontWeight: '600', // Make text bold on hover
    },
  },
};

const { ToDateTime } = timeConverter();


interface TaskListItem {
  id: string;
  patientName: string;
  patientFirstName: string;
  patientLastName: string;
  questionnaireTitle: string;
  authoredDate: string;
  completedDate: string;
  score: number;
  questionnaireResponse: fhir4b.QuestionnaireResponse;
}

export default function TaskList() {
  const { t } = useTranslation();
  const [data, setData] = useState<TaskListItem[]>([]);
  const navigate = useNavigate();
  const token  = useAuthToken();
  const { app, analytics } = useFirebase();

  const navigateToViewPage = (questionnaireResponse: fhir4b.QuestionnaireResponse) => {
    try {
      navigate('/questionnaire', { state: { questionnaireResponse: questionnaireResponse } });
    } catch (error) {
      console.error('Failed to navigate to view page', error);
    }
  };

  useEffect(() => {
    const fetchQuestionnaireResponses = async () => {
      try {

          const query = `{"extension":{"$not":{"$elemMatch":{"url":"https://www.spirit-health.co.uk/fhir/QuestionnaireResponse/reviewed-date"}}}}`

        // Remove query for now until we turn it back on!
        const responseData = await fetchData('crud','APIQuestionnaireResponse', "{}" );

        let mappedData: TaskListItem[] = responseData.map((item: fhir4b.QuestionnaireResponse) => ({
            id: item._id,
            patientId: getPatientId(item),
            patientName: item.subject?.display,
            patientFirstName: getFirstName(item),
            patientLastName: getLastName(item),
            questionnaireTitle: getQuestionnaireTitle(item),
            authoredDate: extractCompletedDate(item),
            reviewedDate: extractReviewedDate(item),
            questionnaireResponse: item,
            score: getQuestionnaireResponseScore(item as fhir4b.QuestionnaireResponse )          
        }));

        mappedData.sort((a, b) => ToDateTime(a.authoredDate).toSeconds() - ToDateTime(b.authoredDate).toSeconds() )

        setData(mappedData);
      } catch (error) {
          console.error('Error fetching data: ', error)
      }
    };

      if(token){
        fetchQuestionnaireResponses();
      }
  }, [token]);

    const handleRowClick = (params: any) => {
     logEvent(analytics, "clinician_view_questionnaireResponse")
     navigateToViewPage(params.row.questionnaireResponse as fhir4b.QuestionnaireResponse)
  };

  const columns: GridColDef[] = [
    {
      field: 'score',
      headerName: t('task.questionnaire-response-rag'),
      renderCell: (params) => (
        <div>
          <RAGRaterChip rag={getQuestionnaireResponseRAG(params.row.questionnaireResponse as fhir4b.QuestionnaireResponse)} />
        </div>
      ),
      flex: 1,
      hideable: false,
    },
    { field: 'patientFirstName', headerName: t('task.first-name'), flex: 1 },
    { field: 'patientLastName', headerName: t('task.last-name'), flex: 1 },

    { field: 'questionnaireTitle', headerName: t('task.questionnaire-name'), flex: 1 },
    {
      field: 'authoredDate',
      headerName: t('task.completed-date'),
      flex: 1,
      hideable: false,
      valueFormatter: (params) => {
        if(!params) return ''
        var luxonDate = ToDateTime(params);
        return luxonDate.toLocaleString(DateTime.DATETIME_MED);
      }
    },
    { field: 'pathwayName', headerName: t('Pathway'), flex: 1 },
    {
      field: 'reviewedDate',
      headerName: t('task.reviewed-date'),
      flex: 1,
      hideable: false
    },
    {

      field: 'viewButton',
      headerName: '',
      renderCell: () => (
        <div className={styles.viewButtonWrapper}>
          <button className="viewButton">{t("command.view")}</button>

        </div>
      ),
      width: 60,
      hideable: false,
    },
  ];

  return (
    <div>
      <h3>{t("task.header")}</h3>

      <div className="table-container">
        <h5 className="table-container-header">{t("task.subheader")}</h5>
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{field:'score', sort: 'desc'}]
            },
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20]}
          getRowId={(row) => row.id}
          onRowClick={handleRowClick}
          sx={customStyles} // Apply custom styles using the sx prop
        />
      </div>
    </div>
  );      
}