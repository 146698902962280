import React, { createContext, useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAqRKPOEGXtGxwtD99axudbM2e8hkE-Yk8",
  authDomain: "clinitouchvie.firebaseapp.com",
  projectId: "clinitouchvie",
  storageBucket: "clinitouchvie.appspot.com",
  messagingSenderId: "559842486994",
  appId: "1:559842486994:web:82b3850fcdd8b9dc879b0b",
  measurementId: "G-NT3SPDKZ8D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
analytics.app.automaticDataCollectionEnabled = true

// Create a context
const FirebaseContext = createContext({ app, analytics });

export const useFirebase = () => useContext(FirebaseContext);

type FireProps = {
  children: any;
};

export const FirebaseProvider = ({ children }: FireProps) => {
  return (
    <FirebaseContext.Provider value={{ app, analytics }}>
      {children}
    </FirebaseContext.Provider>
  );
};
