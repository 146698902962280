////////////////////////////////////////////////////////////////////////////
//
// Copyright 2023 Realm Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
////////////////////////////////////////////////////////////////////////////

import { NavBar } from './NavBar/NavBar';
import styles from '../styles/PageLayout.module.css';

type PageLayoutProps = {
  children: React.ReactNode;
};

/**
 * Wrapper around the `Outlet` for providing a consistent layout.
 */
export function PageLayout({ children }: PageLayoutProps) {
  return (
    <div className={styles.container}>
      <NavBar />
      <main>
        {children}
      </main>
    </div>
  );
}
