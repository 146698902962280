import axiosInstance from './apiBase'

const fetchData = async (route: string = '', collection: String, queryString: String) => {
  try{
    const response = await axiosInstance.get(route,{
      params:{
        collection,
        queryString
      }
    });
    return response.data
  }catch(error){
    console.error('Error fetching data: ', error)
    throw error
  }
}

const sendData = async (route: string = '', collection: String, data: Object) => {
  try{
    const response = await axiosInstance.post(route, data,{
      params:{collection}
    });
    return response.data;
  }catch(error){
    console.error('Error posting data: ', error);
    throw error;
  }
}

const updateData = async (route: string = '', collection: String, docId: String, data: Object) => {
  try{
    const response = await axiosInstance.put(route,
    data,
    {
      params:{
        collection,
        docId
      }
    },);
    return response.data;
  }catch(error){
    console.error('Error putting data: ', error);
    throw error;
  }
}

export { fetchData, sendData, updateData }