import { CodeableConcept } from "./CodeableConcept";

export class CarePlan_activity {
    detail!: CarePlan_activityDetail;
}

export class CarePlan_activityDetail {
    instantiatesCanonical!: string[];
    scheduledTiming!: ScheduledTiming;
}

export class ScheduledTiming {
    repeat?: ScheduledTiming_repeat;
    code?: CodeableConcept 
    static continuous: string = "continuous";
}

export class ScheduledTiming_repeat  {
    period!: number;
    periodUnit!: string;
}
