import styles from '../../styles/TaskPage.module.css';
import  PatientList from '../../components/Patient/PatientList';

export function PatientListPage() {
  return (
    <div className={styles.container}>
       <PatientList />
    </div>
  );
}
