import { Element, Address, PatientCommunication, PatientContact, Reference, Identifier, PatientLink, CodeableConcept, HumanName, Attachment, ContactPoint, FhirResource, Extension, Narrative, Meta } from "fhir/r4b";

export type Gender = "other" | "male" | "female" | "unknown" | undefined;
export class Patient implements fhir4b.Patient {
    resourceType: "Patient" = "Patient";
    active?: boolean | undefined;
    _active?: Element | undefined;
    address?: Address[] | undefined;
    birthDate?: string | undefined;
    _birthDate?: Element | undefined;
    communication?: PatientCommunication[] | undefined;
    contact?: PatientContact[] | undefined;
    deceasedBoolean?: boolean | undefined;
    _deceasedBoolean?: Element | undefined;
    deceasedDateTime?: string | undefined;
    _deceasedDateTime?: Element | undefined;
    gender?: Gender;
    _gender?: Element | undefined;
    generalPractitioner?: Reference[] | undefined;
    identifier?: Identifier[] | undefined;
    link?: PatientLink[] | undefined;
    managingOrganization?: Reference | undefined;
    maritalStatus?: CodeableConcept | undefined;
    multipleBirthBoolean?: boolean | undefined;
    _multipleBirthBoolean?: Element | undefined;
    multipleBirthInteger?: number | undefined;
    name?: HumanName[] | undefined;
    photo?: Attachment[] | undefined;
    telecom?: ContactPoint[] | undefined;
    contained?: FhirResource[] | undefined;
    extension?: Extension[] | undefined;
    modifierExtension?: Extension[] | undefined;
    text?: Narrative | undefined;
    implicitRules?: string | undefined;
    _implicitRules?: Element | undefined;
    language?: string | undefined;
    _language?: Element | undefined;
    meta?: Meta | undefined;
    id: string | undefined;
}

