import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {useNavigate} from 'react-router-dom';
import { useTranslation } from "react-i18next";

import styles from '../../styles/NavBar.module.css';

export function NavBarMenu() {
  const [value, setValue] = React.useState('one');
  const navigate = useNavigate();
  const { i18n, t} = useTranslation();


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  function patient() {
    navigate('/patient');
  }

  function task() {
    navigate('/task');
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="navigation bar"
        textColor="secondary"
        indicatorColor="secondary"
        className="NavMenu"
        >
        <Tab value="one" className={styles.navBarTab} label={t("navbar.dashboard")}  onClick={task}/>
        <Tab value="three" className={styles.navBarTab} label={t("navbar.patients")} onClick={patient} />
      </Tabs>
    </Box>
  );
}